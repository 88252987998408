@import url('@repo/ui-shadcn/styles/globals.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* reset input autofill */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
    -webkit-text-fill-color: #000000 !important;
    transition: background-color 0s 50000s ease-in-out 0s !important;
    caret-color: #000000 !important;
  }
}

